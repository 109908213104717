import React, { useState, useEffect } from 'react'
import { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonBackButton, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonGrid, IonRow, IonCol, IonSpinner, useIonAlert, IonIcon, IonButton } from '@ionic/react';
import './Jobs.css';
import { JobItem, JobItemDetail } from '../types/Jobs'
import {  sendOriCollectorCommand} from '../data/api';
import moment from 'moment/moment'
import "moment/locale/es";
import { informationCircleOutline, timeOutline, warningOutline, checkmarkCircleOutline } from 'ionicons/icons';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // Import the default Tippy styles
import { useGlobalUser } from "../GlobalStoreContext";
import { useWebSocketStore } from '../stores/zustang';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';
import { Toast } from '@capacitor/toast'; // Optional: nice user feedback
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing';
import { useDisplayMode } from "../hooks/useDevice";


type JobItemProps = {
    item: JobItem
}

const JobsDetail = ({item}:JobItemProps) => {  
  const { userId, accessToken, selectedOri } = useGlobalUser();
  const [items, setItems] = useState<JobItemDetail[]>()
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [presentAlert] = useIonAlert();
  const { csvFiles } = useWebSocketStore();
  const { isMobile } = useDisplayMode();

  const requestFilesystemPermission = async () => {
    try {
      await Filesystem.requestPermissions(); // Works for Capacitor v4
    } catch (err) {
      throw new Error("❌ No se pudo pedir permisos");
    }
  };
  
  const handleDownload = async () => {
    const csvContent = [
      items && items[0] ? Object.keys(items[0]).join(';') : '',
      ...(items ?? []).map(item => Object.values(item).join(';')),
    ].join('\n');
  
    const fileName = `job_${item.id_job}.csv`;
  
    try {
      if (Capacitor.getPlatform() === 'web') {
        // Web download
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(url);
      } else {
        // Mobile (Android/iOS)
        await requestFilesystemPermission(); // 🔐 request permission first
  
        const result = await Filesystem.writeFile({
          path: fileName,
          data: csvContent,
          directory: Directory.External,
          encoding: Encoding.UTF8,
        });

        const fullPath = result.uri;
        await SocialSharing.share('', 'Archivo CSV', fullPath);
        /*
        await Toast.show({
          text: `✅ Guardado en Descargas: ${fileName}`,
        });*/
      }
    } catch (error) {
      console.error("❌ Error al guardar archivo", error);
      await Toast.show({
        text: `❌ No se pudo guardar el archivo`,
      });
    }
  };

  const loadItems = async () => {
    sendOriCollectorCommand(userId, selectedOri, "export_csv", {id_job: item.id_job}, accessToken).then(result => {
    }).catch(error => {
      presentAlert({
        header: 'Fallo',
        message: 'No se ha podido cargar los datos.',
        buttons: ['OK'],
    })
    })    
  }

  const sendEmailItems = async () => {
    sendOriCollectorCommand(userId, selectedOri, "email_csv", {id_job: item.id_job}, accessToken).then(result => {
    }).catch(error => {
      presentAlert({
        header: 'Fallo',
        message: 'No se ha podido enviar el mail.',
        buttons: ['OK'],
      })
    })    
  }

  const scanItem = async (position: number) => {
    sendOriCollectorCommand(userId, selectedOri, "scan_qr", {id_job: item.id_job, position: position}, accessToken).then(result => {})
    .catch(error => {
      presentAlert({
        header: 'Fallo',
        message: 'No se ha podido cargar los datos.',
        buttons: ['OK'],
    })
    })
  }
  
  const listDateTime = (item: JobItem, listitem: JobItemDetail) => {
    const item_date = moment(item.date_created).format("DD/MM/yyyy")
    const listitem_date = moment(listitem.date_created).format("DD/MM/yyyy")
    let ret;
    if (listitem_date === item_date) {
      ret = moment(listitem.date_created).format("HH:mm:ss")
    } else {
      ret = moment(listitem.date_created).format("DD/MM/yyyy HH:mm:ss")
    }
    return ret
  }

  const errorDescriptions: Record<0 | 1 | 2, string> = {
    0: 'Okay!', // No error
    1: 'Duplicate tag detected',
    2: 'Empty tag',
  };

  
  useEffect(() => {
    setTimeout(() => loadItems(), 1000)
  }, [])

  useEffect(() => {
    const csvEntry = csvFiles[selectedOri ?? ''];
    if (csvEntry && csvEntry.job_id === item.id_job) {
      console.log("Parsed Value: ",csvFiles);
      const parsed = parseCSV(csvEntry.csv);
      setItems(parsed);
      setIsLoaded(true);
    } else {
      setItems(undefined);
      setIsLoaded(false);
    }
  }, [csvFiles, selectedOri, item.id_job]);
    
  const parseCSV = (csvString: string): JobItemDetail[] => {
    const lines = csvString.trim().split('\n');
    const headers = lines[0].split(';');
  
    return lines.slice(1).map((line) => {
      const values = line.split(';');
      const entry: any = {};
      headers.forEach((header, index) => {
        entry[header.trim()] = values[index]?.trim();
      });
  
      return {
        id_job_item: Number(entry.id_job_item),
        id_job: Number(entry.id_job),
        position: Number(entry.position),
        tag: entry.QR_code,
        date_created: entry.date_item,
        errors: Number(entry.errors),
        farm: entry.farm,
        oricollector: entry.oricollector,
        user: entry.user,
        robot: entry.robot,
        job_date_created: entry.job_date_created,
        antenna: 0 // or 0 or "" depending on your use case
      } as JobItemDetail;
    });
  };
  
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start" >
            <IonBackButton text="Atrás" ></IonBackButton>
          </IonButtons>
          <IonTitle>Proceso</IonTitle>
          {items && items.length > 0 && (
          <IonButtons collapse={false} slot="end">            
            <IonButton onClick={() =>sendEmailItems()}>Email</IonButton>
          </IonButtons>
          )}
          <IonButtons collapse={false} slot="end">            
          {items && items.length > 0 && (
            <IonButton onClick={handleDownload}>
              Download
            </IonButton>
          )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent  >
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Proceso</IonTitle>
          </IonToolbar>
        </IonHeader>
        
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>{item.farm}</IonCardTitle>
            <IonCardSubtitle>Detalles del trabajo con ID: {item.id_job}</IonCardSubtitle>
            <IonCardSubtitle>{moment(item.date_created).format("DD/MM/yyyy HH:mm:ss")}</IonCardSubtitle>
          </IonCardHeader>
          
          <IonCardContent>
            <IonGrid>
              <IonRow>
                <IonCol>
                  Ori Collector<br />
                  <strong>{item.oricollector}</strong>
                </IonCol>
                <IonCol>
                  Robot<br />
                  <strong>{item.robot}</strong>
                </IonCol>
                <IonCol>
                  Usuario<br />
                  <strong>{item.user}</strong>
                </IonCol>
                <IonCol>
                  Muestras<br />
                  <strong>{item.sample_tray}</strong>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>          
        </IonCard>   

        {isLoaded ? (
          <>
          {items && items.length > 0 ? (
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Datos</IonCardTitle>
              </IonCardHeader>
              {isMobile ? (
              // 🔹 Mobile Layout - Cards
                <IonCardContent>
                  <IonGrid className="grid-stripped">
                    <IonRow className="grid-header" style={{ position: 'sticky', top: '0', left: '0' }}>
                      <IonCol>Posición</IonCol>
                      <IonCol>Tag</IonCol>
                      <IonCol>Fecha</IonCol>
                      <IonCol>Scan</IonCol>
                      <IonCol>Status</IonCol>
                    </IonRow>

                    {items?.map((listitem: JobItemDetail) => (
                      <IonRow key={listitem.id_job_item}>
                        <IonCol>{listitem.position}</IonCol>

                        {/* Tag icon */}
                        <IonCol style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          {listitem.tag ? (
                            <Tippy content={listitem.tag}>
                              <IonIcon
                                icon={informationCircleOutline}
                                style={{
                                  color: '#555',
                                  fontSize: '20px',
                                  cursor: 'pointer',
                                }}
                              />
                            </Tippy>
                          ) : (
                            <span style={{ color: '#aaa', fontSize: '14px' }}>–</span>
                          )}
                        </IonCol>

                        {/* Date icon */}
                        <IonCol style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <Tippy content={listDateTime(item, listitem)}>
                            <IonIcon
                              icon={timeOutline}
                              style={{
                                color: '#555',
                                fontSize: '20px',
                                cursor: 'pointer',
                              }}
                            />
                          </Tippy>
                        </IonCol>

                        {/* Scan button */}
                        <IonCol>
                          <IonButton size="small" onClick={() => scanItem(listitem.position)}>
                            Scan
                          </IonButton>
                        </IonCol>

                        {/* Status icon */}
                        <IonCol style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <Tippy content={errorDescriptions[listitem.errors as 0 | 1 | 2]}>
                            {listitem.errors === 0 ? (
                              <IonIcon
                                icon={checkmarkCircleOutline}
                                style={{
                                  color: 'green',
                                  fontSize: '20px',
                                  cursor: 'pointer',
                                }}
                              />
                            ) : (
                              <IonIcon
                                icon={warningOutline}
                                style={{
                                  color: 'red',
                                  fontSize: '20px',
                                  cursor: 'pointer',
                                }}
                              />
                            )}
                          </Tippy>
                        </IonCol>
                      </IonRow>
                    ))}
                  </IonGrid>
                </IonCardContent>

            ) : ( 
              // 🔹 Desktop Layout - Grid
              <IonCardContent>
                <IonGrid className="grid-stripped">
                  <IonRow className="grid-header" style={{position:'sticky', top:'0', left: '0'}}>
                    <IonCol>Posición</IonCol>
                    <IonCol>Tag</IonCol>
                    <IonCol>Fecha</IonCol>
                    <IonCol>Scan</IonCol>
                    <IonCol>Status</IonCol>
                  </IonRow>
                  {items?.map((listitem: JobItemDetail) => (
                    <IonRow key={listitem.id_job_item}>
                      <IonCol>{listitem.position}</IonCol>
                      <IonCol>{listitem.tag}</IonCol>
                      <IonCol>{listDateTime(item, listitem)}</IonCol>
                      <IonCol><IonButton onClick={() =>scanItem(listitem.position)}>Scan</IonButton></IonCol>
                      <IonCol style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {/* Wrap the IonIcon in Tippy to provide a custom tooltip */}
                        <Tippy content={errorDescriptions[listitem.errors as 0 | 1 | 2]}>
                            {listitem.errors === 0 ? (
                                <IonIcon
                                    icon={checkmarkCircleOutline}
                                    style={{ color: 'green', fontSize: '24px', cursor: 'pointer' }}
                                />
                            ) : (
                                <IonIcon
                                    icon={warningOutline}
                                    style={{ color: 'red', fontSize: '24px', cursor: 'pointer' }}
                                />
                            )}
                        </Tippy>
                    </IonCol>
                    </IonRow>
                  ))}
                </IonGrid>
              </IonCardContent>)}
            </IonCard>
          ) : (
            <p className="ion-text-center ion-justify-content-center">No hay datos.</p>
          )}
          </>
        ) : (
          <IonGrid>
            <IonRow>
              <IonCol className="ion-text-center">
                <IonSpinner></IonSpinner>
              </IonCol>
            </IonRow>
          </IonGrid>
          
        )}
      </IonContent>
    </>
  );
};

export default JobsDetail;
